/* Inputs */
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="search"],
input:not([type]),
textarea {
  padding: 10px 15px;
  border: 1px solid var(--input-border-color);
  border-radius: var(--input-border-radius);
  font-size: var(--font-size);
  min-height: 50px;
  /* Remove -webkit-appearance #ZCAPP-697. */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media only screen and (min-width: 992px) {
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="password"],
  input[type="search"],
  input:not([type]),
  textarea {
    padding: 15px 20px;
  }
}

input:disabled,
textarea:disabled,
select:disabled,
textarea {
  opacity: 0.8;
}

::placeholder {
  color: var(--faint-dark-color);
}

textarea {
  padding: 1rem;
  background-color: var(--input-background-color);
}

select {
  padding: 10px 11px;
  border: 1px solid var(--input-border-color);
  background-color: var(--input-background-color);
  border-radius: var(--input-border-radius);
  font-size: var(--font-size);
}

input[type="checkbox"] {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.6rem;
}

input[type="checkbox"] + span {
  font-weight: normal;
  font-size: 1.25rem;
}

.checklist-option {
  --checklist-option-border-radius: var(
    --questionnaire-checklist-option-border-radius
  );
  border-radius: var(--checklist-option-border-radius);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
}

.checklist-option input {
  appearance: none;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  box-shadow: 0 0 0 3px var(--questionnaire-checklist-option-border-color) inset;
  border-radius: var(--checklist-option-border-radius);
  width: 100%;
  padding: 0;
  margin: 0;
  transition: 100ms ease-in-out;
  grid-row: 1;
  grid-column: 1;
  min-height: 150px;
}

@media only screen and (min-width: 600px) {
  .checklist-option input {
    min-height: 100px;
  }
}
@media only screen and (min-width: 768px) {
  .checklist-option input {
    min-height: 150px;
  }
}
@media only screen and (min-width: 992px) {
  .checklist-option input {
    min-height: 200px;
  }
}

.checklist-option input:checked {
  box-shadow: 0 0 2px 5px
    var(--questionnaire-checklist-option-border-color-checked) inset;
}

.checklist-option-label {
  font-weight: normal;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.checklist-option:not(.with-image) .checklist-option-label {
  grid-row: 1;
  grid-column: 1;
  align-self: center;
}

.checklist-option-description {
  font-size: 0.75rem;
  margin: 0;
  text-align: center;
  width: 100%;
}

.disable-user-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Prevent user agent stylesheet on Safari auto add padding, broken OTP number */
.otp-input {
  padding-left: 0;
  padding-right: 0;
}
