.zc-skeleton {
  border-radius: 3px;
  background: linear-gradient(-45deg, #e7e7e7, #dddddd, #c9c7c7, #c9c7c7);
  background-size: 400% 400%;
  animation: skeleton-gradient-move 3s ease-in-out infinite;
}

@keyframes skeleton-gradient-move {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

h1.zc-skeleton::before,
h2.zc-skeleton::before,
h3.zc-skeleton::before,
h4.zc-skeleton::before,
h5.zc-skeleton::before,
h6.zc-skeleton::before,
p.zc-skeleton::before,
span.zc-skeleton::before,
time.zc-skeleton::before,
a.zc-skeleton::before {
  /* To emulate height of text. */
  content: ".";
  display: inline-block;
  color: transparent;
}
