@import "tippy.js/dist/tippy.css";

.tippy-box {
  font-size: 1.2rem;
  color: var(--primary-font-color);
  background-color: var(--background-color);
  border: 1px solid var(--faint-dark-color);
  line-height: 1;
  text-align: center;
  padding: 5px 20px;
}
