/* Custom application color and font settings */
/* :host for shadow dom components */
:root,
:host {
  --primary-color: #701918;
  --primary-contrast-color: white;
  --primary-dark-color: #4d0908;
  --primary-dark-contrast-color: white;
  --primary-light-color: #701918;
  --primary-light-contrast-color: white;

  --accent-color: #184f9c;
  --accent-contrast-color: white;
  --accent-dark-color: #134285;
  --accent-dark-contrast-color: white;
  --accent-light-color: #2065c5;
  --accent-light-contrast-color: white;

  --error-color: rgb(241, 103, 103);

  /* Font */
  --font-size: 12px;
  --font-family: sans-serif;

  /* Base color for a body text. */
  --primary-font-color: #000000;
  --heading-font-color: var(--primary-color);
  --secondary-font-color: #000000;
  --error-font-color: rgb(241, 103, 103);

  /* Color for a text that's not important or it should not draw attention. */
  --faint-color: rgb(213 213 213);
  --faint-dark-color: rgb(135 134 147);
  --faint-light-color: rgb(236 236 236);

  /* Brand colors */

  --background-color: rgb(249 249 249);
  --secondary-background-color: white;

  --success-color: #369d73;
  --success-dark-color: #239468;
  --success-light-color: #3bac7e;

  /* Util properties. Util properties must not define new values. */
  --input-border-color: var(--faint-light-color);
  --input-border-radius: 0;
  --button-border-radius: 0;

  /* Add your variables below, please */
  --hover-transition: 300ms ease-in-out;

  --placeholder-color: var(--faint-color);
  --text-shadow-color: rgba(0, 0, 0, 0.2);
  --default-link-color: #0000ee;

  /* Color for seller's page */
  --option-background-color: #701918;
  --button-background-color: #701918;

  zc-tabs,
  zc-routed-tabs {
    --tab-background: linear-gradient(
      var(--faint-light-color) 0%,
      var(--background-color) 100%
    );
    --tab-selected-background: transparent;
    --tab-color: var(--faint-dark-color);
    --tab-selected-color: var(--primary-color);
    --tab-border: 1px solid var(--faint-color);
    --tab-border-bottom: none;
    --tab-border-radius: 5px 5px 0 0;
  }

  zc-dialog-header {
    justify-content: center;
  }

  --header-z-index: 10;
  --dialog-z-index: 11;
}

[data-theme="grey"] {
  --primary-color: #495665;
  --primary-contrast-color: white;
  --primary-dark-color: #3e4955;
  --primary-dark-contrast-color: white;
  --primary-light-color: #808f9e;
  --primary-light-contrast-color: white;

  --accent-color: #ae9d65;
  --accent-contrast-color: black;
  --accent-dark-color: #a79660;
  --accent-dark-contrast-color: black;
  --accent-light-color: #c9b677;
  --accent-light-contrast-color: black;

  --background-color: #f1f1f1;
  --secondary-background-color: white;

  --primary-font-color: #495665;
  --heading-font-color: #2c353f;

  --faint-color: #d5e3de;
  --faint-dark-color: #2c353f;
  --input-border-radius: 5px;

  /* Using max button border radius to achieve stadium shape */
  --button-border-radius: 999px;

  --option-background-color: #495665;
  --button-background-color: #495665;

  zc-tabs,
  zc-routed-tabs {
    --tab-background: var(--secondary-background-color);
    --tab-selected-background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      #e4e4e4 100%
    );
    --tab-color: var(--faint-dark-color);
    --tab-selected-color: var(--faint-dark-color);
    --tab-border: none;
    --tab-border-radius: 5px;
  }

  zc-dialog-header {
    justify-content: flex-start;
  }
}

[data-theme="remax"] {
  --option-background-color: #e11b22;
  --button-background-color: #e11b22;
}

[data-theme="realtyone"] {
  --primary-color: #646464;
  --primary-dark-color: #4b4a4a;
  --primary-light-color: #757474;

  --accent-color: #9c8218;
  --accent-dark-color: #856e15;
  --accent-light-color: #bd9c1a;

  --option-background-color: #646464;
  --button-background-color: #646464;
}

[data-theme="nexthome"] {
  --primary-color: #ce7700;
  --primary-dark-color: #a76201;
  --primary-light-color: #e08402;

  --accent-color: #ce7700;
  --accent-dark-color: #a76201;
  --accent-light-color: #e08402;

  --option-background-color: #ce7700;
  --button-background-color: #ce7700;
}

[data-theme="kellerwilliams"] {
  --primary-color: #d51a2d;
  --primary-dark-color: #b61525;
  --primary-light-color: #eb1c30;

  --accent-color: #d9d9d9;
  --accent-contrast-color: #d51a2d;
  --accent-dark-color: #969696;
  --accent-dark-contrast-color: #d51a2d;
  --accent-light-color: #f1f1f1;
  --accent-light-contrast-color: #d51a2d;

  --option-background-color: #d51a2d;
  --button-background-color: #d51a2d;
}

[data-theme="exp"] {
  --primary-color: #ce7700;
  --primary-dark-color: #a76201;
  --primary-light-color: #e08402;

  --accent-color: #184f9c;
  --accent-dark-color: #134285;
  --accent-light-color: #2065c5;

  --option-background-color: #ce7700;
  --button-background-color: #ce7700;
}

[data-theme="compass"] {
  --primary-color: #3170fd;
  --primary-dark-color: #2761dd;
  --primary-light-color: #5287fa;

  --accent-color: #3170fd;
  --accent-dark-color: #2761dd;
  --accent-light-color: #5287fa;

  --option-background-color: #3170fd;
  --button-background-color: #3170fd;
}

[data-theme="coldwellbanker"] {
  --primary-color: #0f3263;
  --primary-dark-color: #0d2a52;
  --primary-light-color: #18478a;

  --accent-color: #0f3263;
  --accent-dark-color: #0d2a52;
  --accent-light-color: #18478a;

  --option-background-color: #0f3263;
  --button-background-color: #0f3263;
}

[data-theme="century21"] {
  --primary-color: #9c8218;
  --primary-dark-color: #856e15;
  --primary-light-color: #bd9c1a;

  --accent-color: #646464;
  --accent-contrast-color: #bd9c1a;
  --accent-dark-color: #4b4a4a;
  --accent-dark-contrast-color: #bd9c1a;
  --accent-light-color: #757474;
  --accent-light-contrast-color: #bd9c1a;

  --option-background-color: #9c8218;
  --button-background-color: #9c8218;
}

[data-theme="allentate"] {
  --primary-color: #8b0e04;
  --primary-dark-color: #770b04;
  --primary-light-color: #a71106;

  --accent-color: #384550;
  --accent-dark-color: #262f36;
  --accent-light-color: #475766;

  --option-background-color: #8b0e04;
  --button-background-color: #8b0e04;
}

[data-theme="betterhomesgarden"] {
  --primary-color: #46be90;
  --primary-dark-color: #389974;
  --primary-light-color: #50d6a3;

  --accent-color: #646464;
  --accent-dark-color: #4b4a4a;
  --accent-light-color: #757474;

  --option-background-color: #46be90;
  --button-background-color: #46be90;
}

[data-theme="berkshirehataway"] {
  --primary-color: #4a0d5b;
  --primary-dark-color: #2f083a;
  --primary-light-color: #681180;

  --accent-color: #d9d9d9;
  --accent-contrast-color: #4a0d5b;
  --accent-dark-color: #bdbdbd;
  --accent-dark-contrast-color: #4a0d5b;
  --accent-light-color: #f1f1f1;
  --accent-light-contrast-color: #4a0d5b;

  --option-background-color: #4a0d5b;
  --button-background-color: #4a0d5b;
}

[data-theme="exitrealty"] {
  --primary-color: #98002e;
  --primary-dark-color: #8b012b;
  --primary-light-color: #a30031;

  --accent-color: #000;
  --accent-dark-color: #000;
  --accent-light-color: #1b1b1b;

  --option-background-color: #008c9a;
  --button-background-color: #008c9a;
}

[data-theme="homesmart"] {
  --primary-color: #a90533;
  --primary-dark-color: #8b012b;
  --primary-light-color: #a30031;

  --accent-color: #b5b5b5;
  --accent-dark-color: #a7a7a7;
  --accent-light-color: #bbbbbb;

  --option-background-color: #a90533;
  --button-background-color: #a90533;
}

[data-theme="unitedrealestate"] {
  --primary-color: #a8a9ad;
  --primary-dark-color: #a0a0a1;
  --primary-light-color: #b0b1b6;

  --accent-color: #092a50;
  --accent-dark-color: #092749;
  --accent-light-color: #0a2f58;

  --option-background-color: #092a50;
  --button-background-color: #a8a9ad;
}

[data-theme="custom1"] {
  --primary-color: #71afcb;
  --primary-dark-color: #507c91;
  --primary-light-color: #81c8e9;
  --primary-light-contrast-color: black;

  --accent-color: #153651;
  --accent-dark-color: #122f46;
  --accent-light-color: #1f4f77;

  --option-background-color: #701918;
  --button-background-color: #71afcb;
}

[data-theme="custom2"] {
  --primary-color: #40b9bc;
  --primary-dark-color: #4dd7da;
  --primary-light-color: #2b8586;

  --accent-color: black;
  --accent-dark-color: black;
  --accent-light-color: rgb(48, 48, 48);

  --option-background-color: #701918;
  --button-background-color: #40b9bc;
}

[data-theme="custom3"] {
  --primary-color: #c81933;
  --primary-dark-color: #cc1934;
  --primary-light-color: #c81933;

  --accent-color: black;
  --accent-dark-color: black;
  --accent-light-color: rgb(48, 48, 48);

  --option-background-color: #701918;
  --button-background-color: #c81933;
}

[data-theme="custom4"] {
  --primary-color: #71afcb;
  --primary-dark-color: #507c91;
  --primary-light-color: #81c8e9;
  --primary-light-contrast-color: black;

  --accent-color: #153651;
  --accent-dark-color: #122f46;
  --accent-light-color: #1f4f77;

  --option-background-color: #701918;
  --button-background-color: #71afcb;
}

[data-theme="custom5"] {
  --primary-color: #dd5e17;
  --primary-dark-color: #e96417;
  --primary-dark-contrast-color: black;
  --primary-light-color: #b34e14;

  --accent-color: #403427;
  --accent-dark-color: #2b2219;
  --accent-light-color: #776048;

  --option-background-color: #701918;
  --button-background-color: #dd5e17;
}
