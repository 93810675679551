a {
  color: var(--accent-dark-color);
  font-weight: bold;
  text-decoration: none;
  transition: 100ms ease-in-out;
}

a:active {
  color: var(--accent-light-color);
}
