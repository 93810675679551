/* Question heading */
.question-text {
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 600px) {
  .question-text {
    font-size: 2.5rem;
  }
}
@media only screen and (min-width: 992px) {
  .question-text {
    font-size: 2.875rem;
    margin-bottom: 0.625rem;
  }
}

/* Question Description */
.question-description {
  font-size: 1rem;
  margin: 0 0 1rem;
}

@media only screen and (min-width: 600px) {
  .question-description {
    font-size: 1.25rem;
    margin: 0 0 1.5rem;
  }
}
@media only screen and (min-width: 992px) {
  .question-description {
    font-size: 1.625rem;
  }
}
