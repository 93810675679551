label {
  display: inline-block;
}

label > span {
  font-weight: normal;
  font-size: 1.25rem;
  display: block;
  width: fit-content;
  margin-top: 5px;
  margin-bottom: 5px;
}
