details.zc-animated > *:not(summary) {
  animation: open 0.2s linear;
}

@keyframes open {
  0% {
    opacity: 0.7;
    margin-top: -20px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}