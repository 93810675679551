// Angular custom theme.

// Custom application styles.
@import url(https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined);
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "../../../common/shared/theme/variables.scss";
@import "../../../common/shared/theme/elements/style.css";
@import "./angular-material-custom-theme.scss";
@import "./elements/style.css";
@import "./utils.css";

:root {
  --font-family: "Work Sans", sans-serif;
  --font-size: 16px;
  --focus-outline: 2px solid var(--accent-light-color);

  /* Questionnaire controls */
  --questionnaire-checklist-option-border-radius: 20px;
  --questionnaire-checklist-option-border-color: var(--faint-color);
  --questionnaire-checklist-option-border-color-checked: var(--primary-color);

  --border-checklist-color: #979797;
  --label-checklist-background-color: #fff;
  --checkmark-color: var(--primary-color);
  --checkbox-background-color: #fff;
  --input-background-color: #fff;
  --select-border-color: #d4d4d4;
  --primary-font-color: #212529;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  overflow: hidden;

  // Resolves issue with scrolling to fragment.
  position: fixed;
  height: 100%;
  width: 100%;
}

body {
  overflow: auto;
}

*:focus-visible {
  outline: var(--focus-outline);
}

html,
body {
  margin: 0;
  color: var(--primary-font-color);
  font-size: var(--font-size);
  font-family: var(--font-family);
  height: 100%;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

.line {
  color: inherit;
  height: 1px;
  opacity: 0.25;
  width: 150px;
  margin: 15px auto;
  background-color: currentColor;
}
