.visually-hidden {
  /*
    To preserve a11y, hide radio button only visually
    https://www.sarasoueidan.com/blog/inclusively-hiding-and-styling-checkboxes-and-radio-buttons/
  */
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  margin: 0;
}

/* Loading state. */

.zc-loading {
  --spinner-color: white;
  --spinner-size: 12px;

  cursor: progress;
  position: relative;
  overflow: hidden;
}

.zc-loading::after {
  position: absolute;
  top: calc(50% - var(--spinner-size) / 2);
  left: calc(50% - var(--spinner-size) / 2);
  content: " ";
  display: block;
  width: var(--spinner-size);
  height: var(--spinner-size);
  border-radius: 50%;
  border: 2px solid var(--spinner-color);
  border-color: var(--spinner-color) transparent var(--spinner-color)
    transparent;
  animation: zc-loading-spin 1.2s ease-in-out infinite;
  z-index: 1;
}

.zc-loading::before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  animation: fadein 200ms ease-in;
  /* To balance loader positioning on flexbox */
  content: "";
}

@keyframes zc-loading-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Helper style for body, prevent element with position absolute moving when scroll. */
[data-position="fixed"] {
  position: fixed;
  width: 100%;
}
