@use "@angular/material" as mat;
@use "~@matheo/datepicker/theming" as datepicker;

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Set the neutral Material UI theme until they're able to support CSS custom properties for theming
// https://github.com/angular/components/issues/4352
$z-primary: (
  main: #495665,
  dark: #3e4955,
  light: #808f9e,
  contrast: (
    main: white,
    dark: white,
    light: white,
  ),
);

$z-accent: (
  main: #728294,
  dark: #627181,
  light: #9fafc0,
  contrast: (
    main: white,
    dark: white,
    light: white,
  ),
);

/*
  Signature of mat-palette.

  mat.define-palette(
    scss variable with shape { key1: color, key2: color, key3: color, contrast: { ...repeat all keys } }, (example above)
    key of primary color,
    key of darken color,
    key of lighten color,
    (optional) key of primary contrast - default contrast[key_of_primary_color]
  )
*/

$app-primary: mat.define-palette($z-primary, main, dark, light);
$app-accent: mat.define-palette($z-accent, main, dark, light);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme($app-primary, $app-accent);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);
// The same color styles are generated multiple times... in console is intended due to specifics of datepicker library.
@include datepicker.mat-datepicker-theme($app-theme);
