.light-gallery {
  --background-color: var(--primary-contrast-color);
}

.light-gallery .lg-image {
  background-color: var(--background-color);
  min-width: 100px;
  min-height: 100px;
}

.light-gallery .lg-has-iframe {
  /* Make the iframe always at the top of the screen */
  position: absolute;
  top: 50px;
}

.light-gallery .lg-has-iframe .lg-object {
  /* Make the iframe not overlap lightgallery buttons, heading */
  width: 90%;
  height: 90%;
}

.light-gallery .lg-outer .lg-thumb-item img {
  object-fit: contain;
}

.light-gallery_large-image .lg-object.lg-image {
  height: 90%;
  object-fit: contain;
}

.light-gallery_black-background .lg-object.lg-image {
  --background-color: black;
}