a.button,
button.primary,
button.secondary {
  border: 0;
  cursor: pointer;
  font-weight: bold;
  transition: 100ms ease-in-out;
  padding: 15px 20px;
  font-size: 1.0625rem;
}

a.button:disabled,
button.primary:disabled,
button.secondary:disabled {
  opacity: 0.7;
}

a.primary,
button.primary {
  background-color: var(--primary-color);
  color: var(--primary-contrast-color);
}

a.primary:hover:not(:disabled),
button.primary:hover:not(:disabled) {
  background: var(--primary-color);
}

a.primary:active:not(:disabled),
button.primary:active:not(:disabled) {
  background-color: var(--primary-dark-color);
}

a.secondary,
button.secondary {
  --input-border-color: var(--primary-color);
  box-shadow: 0 0 0 1px var(--input-border-color) inset;
  background: var(--primary-contrast-color);
  color: var(--primary-color);
}

a.secondary:hover:not(:disabled),
button.secondary:hover:not(:disabled) {
  --input-border-color: var(--primary-color);
}

a.secondary:active:not(:disabled),
button.secondary:active:not(:disabled) {
  --input-border-color: var(--primary-dark-color);
}
